import { SLOW_REQUEST_CONFIG } from './loadingUtil';

import axios from 'axios';
import { salvarAmbiente } from './applicationContext';

export const BUSCAR_AMBIENTE = '@cidadeActions/BUSCAR_AMBIENTE';

const URL = process.env.NODE_ENV === 'production'
        ? 'http://179.251.255.8:8591'
        : 'http://localhost:8083';

const VERSION = 'develop';
export { URL, VERSION }

export const ambienteUrl = `${URL}/ambiente`;

export const recuperarAmbiente = callback => dispatch => axios.get(ambienteUrl, SLOW_REQUEST_CONFIG)
    .then((response) => {
        const ambiente = response.data;
        salvarAmbiente(ambiente);
        if (callback) {
            callback();
        }
        return dispatch({
            type: BUSCAR_AMBIENTE,
            payload: ambiente
        });
    });
